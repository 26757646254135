import React from 'react'
import { Link } from 'gatsby'
import { Section, Columns, Box } from 'react-bulma-components'

function About() {
  return (
    <Section className="has-background-white-ter mt-6 mb-0 content">
      <Columns>
        <Columns.Column className="is-8 is-offset-2">
          <Box className="is-rounded p-8">
            <h1>About</h1>
            <p>
              We are UK app developers based in{' '}
              <a href="https://www.google.com/search?q=leeds&rlz=1C5CHFA_enGB939GB939&sxsrf=ALiCzsZ_2Y16A0O_Eua2GttfErXQuIl_1g%3A1661238617545&ei=WX0EY-zzIJfMgQb8lZHoAQ&ved=0ahUKEwishL6NtNz5AhUXZsAKHfxKBB0Q4dUDCA8&uact=5&oq=leeds&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIECCMQJzIECCMQJzIECCMQJzIKCC4QsQMQgwEQQzIKCAAQsQMQgwEQQzIKCAAQsQMQgwEQQzIKCAAQsQMQgwEQQzIKCC4QsQMQgwEQQzINCC4QsQMQgwEQ1AIQQzIKCAAQsQMQgwEQQzoHCAAQRxCwAzoHCAAQsAMQQzoRCC4QsQMQgwEQxwEQ0QMQkQI6CwguEIAEELEDEIMBOgsILhDHARDRAxCRAjoOCC4QgAQQsQMQgwEQ1AI6BQgAEIAEOhEILhCABBCxAxCDARDHARDRAzoICAAQsQMQgwE6CwgAEIAEELEDEIMBOhMILhCxAxCDARDHARDRAxDUAhBDOgsILhCABBDHARCvAToKCC4QxwEQrwEQQ0oECEEYAEoECEYYAFCeBFiCB2C_CGgBcAF4AIABb4gB5AOSAQMzLjKYAQCgAQHIAQrAAQE&sclient=gws-wiz-serp">
                Leeds
              </a>
              . Founded in 2012, Pocketworks has grown to 19 people across five
              European countries.
            </p>
            <p>
              Our clients hire us as a long-term partner, assisting them with
              their mobile strategy, market research, user research, design,
              mobile development, cloud development, quality assurance and
              mobile growth hacking.
            </p>
            <p>
              The mobile <Link to="/about/technologies">technologies</Link> we
              use include Flutter, iOS and Android. For cloud solutions, we tend
              to use Elixir and Postgres. We also have some solutions in Python,
              .NET, Rails and Laravel.
            </p>
            <p>
              We are also a UX research and design studio, helping our clients
              take a customer-centric approach to modern digital product
              development. Finally, we encourage joint investment ventures where
              we share equity and revenue.
            </p>
            <Link
              className="button is-primary is-leafy is-medium"
              to="/about/story"
            >
              Learn more about Pocketworks
            </Link>
          </Box>
        </Columns.Column>
      </Columns>
    </Section>
  )
}

export default About
